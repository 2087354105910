import PropTypes from "prop-types";
import React, { useState } from "react";
import { navigate, Link } from "gatsby";
import { useTranslation } from "react-i18next";

import { HomeIcon } from "./icons";
import { windowGtagEvent } from "../utils/gtag";
import EPDDesktopEnLogo from "../images/EPD-logo_eng.jpg";
import EPDMobileEnLogo from "../images/EPD-logo_eng_mobile.jpg";
import EPDDesktopTcLogo from "../images/EPD-logo_tri_chi.jpg";
import EPDDesktopScLogo from "../images/EPD-logo_simp_chi.jpg";
import styles from "./header.module.less";

const Header = params => {
    const [burger, setBurger] = useState(false);
    const { t } = useTranslation();

    const setFontSizeClass = textSizeStr => {
        params.setFontSizeClass(textSizeStr);
        window.localStorage &&
            window.localStorage.setItem("fontSize", textSizeStr);
    };

    const setSmallFont = e => {
        e.preventDefault();
        windowGtagEvent("font_size", "change", "small");
        setFontSizeClass("textSmall");
    };

    const setMediumFont = e => {
        e.preventDefault();
        windowGtagEvent("font_size", "change", "medium");
        setFontSizeClass("textMedium");
    };

    const setLargeFont = e => {
        e.preventDefault();
        windowGtagEvent("font_size", "change", "large");
        setFontSizeClass("textLarge");
    };

    const FontSizeLabel = ({ size }) =>
        // TODO: Better to underline current font size? (size === params.fontSizeClass ? <u>A</u> : "A";)
        "A";

    let sideOpen = burger ? styles.open : styles.closed;

    let EPDDesktopLogo, EPDMobileLogo;
    switch (params.lang) {
        case "en":
            EPDDesktopLogo = EPDDesktopEnLogo;
            EPDMobileLogo = EPDMobileEnLogo;
            break;
        case "tc":
            EPDDesktopLogo = EPDDesktopTcLogo;
            EPDMobileLogo = EPDDesktopTcLogo;
            break;
        case "sc":
            EPDDesktopLogo = EPDDesktopScLogo;
            EPDMobileLogo = EPDDesktopScLogo;
            break;
        default:
            EPDDesktopLogo = EPDDesktopEnLogo;
            EPDMobileLogo = EPDMobileEnLogo;
            break;
    }

    const LangSwitcher = () => {
        return (
            <>
                <button
                    className={styles.controlButton}
                    aria-label={t("Change language to Traditional Chinese")}
                    onClick={() => {
                        windowGtagEvent("lang", "change", "tc");
                        navigate("/tc/" + params.path + window.location.search);
                    }}
                >
                    繁
                </button>
                <div>|</div>
                <button
                    className={styles.controlButton}
                    aria-label={t("Change language to Simplified Chinese")}
                    onClick={() => {
                        windowGtagEvent("lang", "change", "sc");
                        navigate("/sc/" + params.path + window.location.search);
                    }}
                >
                    簡
                </button>
                <div>|</div>
                <button
                    className={styles.controlButton}
                    aria-label={t("Change language to English")}
                    onClick={() => {
                        windowGtagEvent("lang", "change", "en");
                        navigate("/en/" + params.path + window.location.search);
                    }}
                >
                    Eng
                </button>
            </>
        );
    };

    return (
        <header className={styles.headerContainer}>
            <div className={`${styles.sidenav} ${sideOpen} mobile`}>
                <div className={styles.navList}>
                    <div>
                        <button
                            className={styles.closebtn}
                            aria-label={t("Close menu")}
                            onClick={() => {
                                setBurger(!burger);
                            }}
                        >
                            &times;
                        </button>
                    </div>
                    <div className={styles.navItem}>
                        <LangSwitcher />
                    </div>
                    <div className={styles.navItem}>
                        <button
                            className={styles.controlButton}
                            style={{
                                fontSize: "0.675rem",
                                marginRight: "0.75rem",
                            }}
                            aria-label={t("Small font size")}
                            onClick={setSmallFont}
                        >
                            <FontSizeLabel size="textSmall" />
                        </button>
                        <button
                            className={styles.controlButton}
                            style={{
                                fontSize: "0.875rem",
                                marginRight: "0.75rem",
                            }}
                            aria-label={t("Medium font size")}
                            onClick={setMediumFont}
                        >
                            <FontSizeLabel size="textMedium" />
                        </button>
                        <button
                            className={styles.controlButton}
                            style={{ fontSize: "1rem" }}
                            aria-label={t("Large font size")}
                            onClick={setLargeFont}
                        >
                            <FontSizeLabel size="textLarge" />
                        </button>
                    </div>
                </div>
            </div>
            <div className={styles.lt}>
                <button
                    className={`${styles.burger} mobile`}
                    aria-label={t("Open menu")}
                    onClick={() => {
                        setBurger(!burger);
                    }}
                >
                    &#9776;
                </button>
                <a
                    href={t("EPDUrl")}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={t("EPD")}
                >
                    <img
                        src={EPDDesktopLogo}
                        alt={t("EPD")}
                        className={`${styles.logo} desktop`}
                    />
                    <img
                        src={EPDMobileLogo}
                        alt={t("EPD")}
                        className={`${styles.logo} mobile`}
                    />
                </a>
            </div>
            <div className={styles.rt}>
                <div className={styles.controlGroup}>
                    <div className="mr1">
                        <Link
                            to={"/" + params.lang}
                            className={styles.homeBtn}
                            aria-label={t("BackToHome")}
                        >
                            <HomeIcon width="22px" title={t("BackToHome")} />
                        </Link>
                    </div>
                    <div
                        className="desktop mr1"
                        style={{ display: "table-cell" }}
                    >
                        <button
                            className={styles.controlButton}
                            style={{
                                fontSize: "0.675rem",
                                verticalAlign: "baseline",
                            }}
                            aria-label={t("Small font size")}
                            onClick={setSmallFont}
                        >
                            <FontSizeLabel size="textSmall" />
                        </button>
                        <button
                            className={styles.controlButton}
                            style={{
                                fontSize: "0.875rem",
                                verticalAlign: "baseline",
                            }}
                            aria-label={t("Medium font size")}
                            onClick={setMediumFont}
                        >
                            <FontSizeLabel size="textMedium" />
                        </button>
                        <button
                            className={styles.controlButton}
                            style={{
                                fontSize: "1rem",
                                verticalAlign: "baseline",
                            }}
                            aria-label={t("Large font size")}
                            onClick={setLargeFont}
                        >
                            <FontSizeLabel size="textLarge" />
                        </button>
                    </div>
                    <div className="desktop">
                        <LangSwitcher />
                    </div>
                </div>
            </div>
        </header>
    );
};

Header.propTypes = {
    siteTitle: PropTypes.string,
};

Header.defaultProps = {
    siteTitle: ``,
};

export default Header;
