import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import En from "../assets/i18n/en.yaml";
import Tc from "../assets/i18n/tc.yaml";
import Sc from "../assets/i18n/sc.yaml";

// the translations
const resources = {
    en: {
        translation: En,
    },
    tc: {
        translation: Tc,
    },
    sc: {
        translation: Sc,
    },
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "en",

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
