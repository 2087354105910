import React from "react";

// Use SVG icons from https://icons.getbootstrap.com

const SvgIcon = ({ width, children, cssClass = "", title = "" }) => (
    <svg
        width={width}
        height={width}
        viewBox="0 0 16 16"
        className={cssClass}
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        {title && <title>{title}</title>}
        {children}
    </svg>
);

const ChevronRightFill = ({ width = "16px", cssClass = "" }) => (
    <SvgIcon width={width} cssClass={cssClass}>
        <path d="M12.14 8.753l-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
    </SvgIcon>
);

const HomeIcon = ({ width = "16px", cssClass = "", title = "" }) => (
    <SvgIcon width={width} cssClass={cssClass} title={title}>
        <path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5z" />
    </SvgIcon>
);

const MapMarker = ({
    number,
    isOnline = true,
    width = "25",
    height = "30",
    cssClass = "inline-block mr1",
}) => {
    const wpx = width + "px";
    const hpx = height + "px";
    return (
        <div
            className={
                "leaflet-marker-icon extra-marker extra-marker-svg lf-marker-inline " +
                cssClass
            }
            aria-hidden="true"
            style={{ width: wpx, height: hpx }}
        >
            <svg
                width={wpx}
                height={hpx}
                viewBox="0 0 35 45"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M17.5 2.746c-8.284 0-15 6.853-15 15.307 0 .963.098 1.902.265 2.816a15.413 15.413 0 002.262 5.684l.134.193 12.295 17.785 12.439-17.863.056-.08a15.422 15.422 0 002.343-6.112c.123-.791.206-1.597.206-2.423 0-8.454-6.716-15.307-15-15.307"
                    fill={isOnline ? "#1f78c7" : "#6f6f6f"}
                ></path>
            </svg>
            <i
                number={number}
                style={{
                    width: width - 1 + "px",
                    paddingRight: "0.125rem",
                }}
                className="fa fa-number"
            ></i>
        </div>
    );
};

export { ChevronRightFill, HomeIcon, MapMarker };
