export const windowGtagEvent = (eventName, key, value) => {
    if (typeof window !== `undefined`) {
        if (window.gtag) {
            window.gtag("event", key, {
                event_category: eventName,
                event_label: value,
            });
        } else {
            /* eslint-disable no-console */
            console.debug(
                `window.gtag('event', '${key}', { event_category: '${eventName}', event_label: '${value}'})`
            );
            /* eslint-enable no-console */
        }
    }
};
